<template>
  <div id="redefine-senha">
    <div id="redefine-senha-modal" class="modal-forms" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" style="margin:auto" role="document">
        <!--  -->
        <div class="modal-content" id="forgot-password-modal-content" v-if="exibeConfirmacao">
          <div class="modal-header">
            <h4 class="modal-title">Senha redefinida!</h4>
          </div>
          <div class="modal-body">
            <p
              class="text-center"
            >Clique no botão a baixo para acessar o portal de auto atendimento.</p>
            <!-- <form method="post" id="Forgot-Password-Form2" role="form"> -->
              <button
                type="submit"
                class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
                v-on:click="acessarPortal"
              >Acessar Portal</button>
            <!-- </form> -->
          </div>
        </div>
        <!--  -->
        <div class="modal-content" id="redefine-senha-content" v-if="exibeForm">
          <div class="modal-header">
            <h4 class="modal-title text-uppercase">Redefinição de Senha</h4>
          </div>
          <div class="modal-body">
            <p class="text-center">Informe sua nova senha.</p>
            <form method="post" id="Reset-Password-Form" role="form">
              <input name="token" type="hidden" required v-model="token"/>
              
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-addon"><i class="icon fas fa-lock"></i></div>
                  <!-- <input name="password" id="login-password" type="password" class="form-control input-lg" placeholder="sua senha" required data-parsley-minlength="6" data-parsley-trigger="keyup"> -->
                  <input
                    name="password"
                    type="password"
                    class="form-control input-lg"
                    placeholder="Senha"
                    required
                    data-parsley-minlength="6"
                    data-parsley-trigger="keyup"
                    v-model="senha"
                  />
                </div>                      
              </div>

              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-addon">
                    <i class="icon fas fa-lock"></i>
                  </div>
                  <input
                    name="password"
                    id="confirm-passwd"
                    type="password"
                    class="form-control input-lg"
                    placeholder="Confirme sua senha"
                    required
                    data-parsley-equalto="#passwd"
                    data-parsley-trigger="keyup"
                    v-model="confirmaSenha"
                  />
                </div>
              </div>
              <div v-if="errors.length">
                <ul>
                  <li
                    class="text-danger"
                    v-for="(error, index)  in errors"
                    v-bind:key="index"
                  >{{ error }}</li>
                </ul>
              </div>
              <button
                type="submit"
                class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
                data-remote="false"
                data-target="#password-reset-modal"
                v-on:click="redefinir"
              >Redefinir</button>
            </form>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";

export default {
  name: "RedefineSenha",
  data() {
    return {
      errors: [],
      solicitacaoRealizada: false,
      token:"",
      senha: "",
      confirmaSenha: ""
    };
  },
  computed: {
    exibeForm: function() {
      return !this.solicitacaoRealizada;
    },
    exibeConfirmacao: function() {
      return this.solicitacaoRealizada;
    }
  },
  mounted() {
    this.token = this.$route.query.token || 'sem token';
  },
  methods: {
    acessarPortal: function(event) {
      if (event) {
        event.preventDefault();
      } 
      this.$router.push("/");
    },
    redefinir: function(event) {
      if (event) {
        event.preventDefault();
      }
      this.errors = [];

      if (this.senha.length == 0) {
        this.errors.push("Informa sua senha.");
      } else if (this.senha != this.confirmaSenha) {
        this.errors.push("Verifique a confirmação da senha.");
      } else if (!this.validPassword(this.senha)) {
        this.errors.push(
          "A senha deve possuir entre 6 e 8 dígitos,  conter pelo ao menos uma letra maiúscula, uma minúscula e número."
        );
      }

      if (this.errors.length == 0) {
        let acessoApi = new ApiPortalAutoAtendimento.AcessoApi();
        
        acessoApi.definiSenhaAcesso(this.senha, this.token, (error, data, response) => {
          if (!error) {
            this.solicitacaoRealizada = true;
          }
        });
      }
    },
    validPassword: function(value) {
      let re = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,8})/g;
      return re.test(value);
    },
    validEmail: function(value) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value);
    }
  }
};
</script>

<style scope>
#redefine-senha {
  background-image:url(../assets/img/banner1.png); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 767.98px) {
 #redefine-senha {
    background-image: url(../assets/img/bg_mobile.png);
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .modal-content {
      padding: 30px 30px 0;
  }
}

</style>